.ant-popover-buttons .ant-btn:nth-child(1) {
  color: @b-60;
  border-color: @b-60;
  background-color: @b-20;
}

.ant-btn-loading-icon .anticon {
  margin-right: 8px;
}

.@{class-prefix}-placement {
  overflow-x: auto;
  overflow-y: hidden;

  .ant-btn {
    text-align: center;
    padding: 0 8px;
    min-width: 82px;
    height: 40px;
  }
}
