@media print {
  .@{class-prefix}-pdf-a4 {
    padding: 20px;
    background-color: #fff;
    size: "A4" !important;
    margin: 0 10mm;
    font-size: 14pt;
  }
}

.@{class-prefix}-letterhead {
  .@{class-prefix}-letterhead-logo {
    width: 200px;
    padding: 0;
    margin-right: 24px;
  }

  .@{class-prefix}-company {
    margin-bottom: 8px;
    color: @danger-1;
  }

  .@{class-prefix}-tagline {
    color: @primary-1;
  }

  .@{class-prefix}-info {
    margin-bottom: 0;
  }
}
