.ant-form-item-label {
  text-align: left !important;
}

.ant-form-item-label > label::after {
  content: "" !important;
}

.@{class-prefix}-calendar .fc-timegrid-event .fc-event-main {
  padding: 4px;
}

.ant-input-number-affix-wrapper {
  background: none !important;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip {
  color: @primary-1;
}

// // Input Remove Required & Showing Optional
// .ant-form-item-label
//   > label.ant-form-item-required:not(
//     .ant-form-item-required-mark-optional
//   )::before {
//   content: "" !important;
// }
// .ant-form-item-label
//   > label.ant-form-item-required:not(
//     .ant-form-item-required-mark-optional
//   )::after {
//   content: "*" !important;
//   color: @danger-1;
// }

// .ant-form-item-label > label:not(.ant-form-item-required)::after {
//   display: inline-block !important;
//   content: "(opsional)" !important;
//   color: @b-60;
// }

.@{class-prefix}-tabs-full {
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
    display: block; /* centers text inside tabs */
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }
}

.@{class-prefix}-tabs-solid .ant-tabs-nav-list {
  background: @b-0;
}

.@{class-prefix}-dashboard-carousel{
  height: 200px;
}

.ant-input-group-addon{
  font-size: 14px;
}

.ant-input-number-group-addon{
  font-size: 14px;
}

// ====== CUSTOM DARK MODE ====

body.dark {
  color: @b-0;

  small {
    color: @b-0;
  }

  p{
    color: @b-0;
  }

  .ant-picker:hover{
    background: @dark-100;
  }
  .ant-input-number-affix-wrapper {
    background: none !important;
    color: @b-0 !important;
    border-color: @dark-80;
  }


 .ant-input-group-addon{
  background: none !important;
  color: @b-0 !important;
  border-color: @dark-80;
 }

  .ant-input-number-group-addon {
    background: none !important;
    color: @b-0 !important;
    border-color: @dark-80;
  }

  .ant-table-cell-row-hover {
    background: @dark-90 !important;
  }
  .row-dragging {
    background: @dark-90 !important;
    border: 1px solid @dark-90;
  }

  .ant-popover-arrow-content::before {
    background: @dark-100;
  }

  .@{class-prefix}-tabs-solid .ant-tabs-nav-list {
    background: @dark-90;
  }
  .ant-input-prefix {
    color: @b-0;
  }
  
  .ant-input-suffix {
    color: @b-0;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    background: @dark-100 !important;
  }

  .ant-input-number-input, .ant-input-number-input-wrap{
    background: @dark-100 !important;
    border-radius: 6px;
  }
}

thead th.@{class-prefix}-thead-none{
  display: none !important;
}

.@{class-prefix}-show-desktop{
  display: block;
}
.@{class-prefix}-show-mobile{
  display: none;
}

@media @lg{
  .@{class-prefix}-show-desktop{
    display: none;
  }
  .@{class-prefix}-show-mobile{
    display: block;
  }
}

.@{class-prefix}-input-otp {
  .ant-input-number-input {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 0;
  }
}

.@{class-prefix}-home-icon {
  font-size: 12px !important;
}

#odontogram polygon:hover {
  fill: lightgray;
}

