.@{class-prefix}-switch-data-list{
    .ant-radio-button-wrapper{
        border: none;
        background: none;
        color: @grey-1;
        font-size: 20px;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
        box-shadow: none;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
        height: 50%;
        top: 15%;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before{
        background-color: @grey-1 !important;
    }

    .ant-radio-button-wrapper-checked{
        border: none;
        background: none;
        color: @primary-1;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
        border-color:@grey-2;
    }

    .ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border: none;
}

.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background-color: @primary-4;
        color: @primary-1;
        border: 1px solid @primary-1 !important;
    }
}


body.dark{
    .@{class-prefix}-switch-data-list{
        .ant-radio-button-wrapper{
            background: none;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
            background: none;
            color: @primary-2;
        }
    }
}