.@{class-prefix}-app-menu-layout{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 12px;
    z-index: 100;
    display: none;
  
    .@{class-prefix}-app-menu{
      color: @b-60;
      position: relative;
      display: block;
      width: 100%;
  
      &.active{
        color: @primary-1;
      }
    }
  
    .@{class-prefix}-floating-menu{
      text-align: center;
      background-color: @primary-1;
      color: @b-0;
      border-radius: 100%;
      aspect-ratio: 1 / 1;
      width: 72px;
      height: 72px;
      position: absolute;
      bottom: 24px;
      padding: 8px;
    }
}

@media @lg {
  .@{class-prefix}-app-menu-layout{
    display: flex;
  }
}