.pincode-input-text {
  color: @primary-1;
  font-size: 40px !important;
  border: none !important;
  font: small-caption;

  &::placeholder {
    color: @grey-2;
    opacity: 1; /* Firefox */
    font: small-caption;
  }

  &::-ms-input-placeholder {
    color: @grey-2;
    opacity: 1;
    font: small-caption;
  }
}
