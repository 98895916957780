// Color Variables
@darkColors: ~"dark-0" @dark-0, ~"dark-5" @dark-5, ~"dark-10" @dark-10,
  ~"dark-15" @dark-15, ~"dark-20" @dark-20, ~"dark-30" @dark-30,
  ~"dark-35" @dark-35, ~"dark-40" @dark-40, ~"dark-45" @dark-45,
  ~"dark-50" @dark-50, ~"dark-60" @dark-60, ~"dark-70" @dark-70,
  ~"dark-80" @dark-80, ~"dark-85" @dark-85, ~"dark-90" @dark-90,
  ~"dark-100" @dark-100, ~"dark-primary-2" @primary-2,
  ~"dark-primary-1" @primary-1, ~"dark-primary" @primary-dark,
  ~"dark-danger" @danger-dark, ~"dark-error" @danger-dark,
  ~"dark-warning" @warning-dark, ~"dark-success" @success-dark,
  ~"dark-secondary" @secondary-dark, ~"dark-info" @info-dark,
  ~"dark-orange" @orange-dark, ~"dark-grey" @grey-dark;

// Text Color Hover Map
.dark-text-color-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-hover-@{prefix}-color-@{key} {
        &:hover {
          color: @value !important;
        }
      }
    }
  }
}

.dark-text-color-hover-classes(text, @darkColors);

// Text Color Map
.dark-text-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-@{prefix}-color-@{key} {
        color: @value !important;
      }
    }
  }
}

.dark-text-color-classes(text, @darkColors);

// Background Color Hover Map
.dark-bg-color-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-hover-@{prefix}-color-@{key} {
        &:hover {
          background-color: @value !important;
        }
      }
    }
  }
}

.dark-bg-color-hover-classes(bg, @darkColors);

// Background Color Map
.dark-bg-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-@{prefix}-color-@{key} {
        background-color: @value !important;
      }
    }
  }
}

.dark-bg-color-classes(bg, @darkColors);

// Background Hover Map
.dark-bg-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-hover-@{prefix}-@{key} {
        &:hover {
          background: @value !important;
        }
      }
    }
  }
}

.dark-bg-hover-classes(bg, @darkColors);

// Background Map
.dark-bg-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-@{prefix}-@{key} {
        background: @value !important;
      }
    }
  }
}

.dark-bg-classes(bg, @darkColors);

// Border Color Hover Map
.dark-border-color-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-hover-@{prefix}-color-@{key} {
        &:hover {
          border-color: @value !important;
        }
      }
    }
  }
}

.dark-border-color-hover-classes(border, @darkColors);

// Border Color Map
.dark-border-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-@{prefix}-color-@{key} {
        border-color: @value !important;
      }
    }
  }
}

.dark-border-color-classes(border, @darkColors);

// Fill Map
.dark-fill-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-@{prefix}-@{key} {
        path {
          fill: @value !important;
        }
      }
    }
  }
}

.dark-fill-color-classes(fill, @darkColors);

// Fill Hover Map
.dark-fill-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-hover-@{prefix}-@{key} {
        &:hover {
          path {
            fill: @value !important;
          }
        }
      }
    }
  }
}

.dark-fill-hover-classes(fill, @darkColors);

// Stroke Map
.dark-stroke-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-@{prefix}-@{key} {
        path,
        rect,
        line,
        circle {
          stroke: @value !important;
        }
      }
    }
  }
}

.dark-stroke-color-classes(stroke, @darkColors);

// Stroke Hover Map
.dark-stroke-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    body.dark {
      .@{class-prefix}-hover-@{prefix}-@{key} {
        &:hover {
          path,
          rect,
          line,
          circle {
            stroke: @value !important;
          }
        }
      }
    }
  }
}

.dark-stroke-hover-classes(stroke, @darkColors);
